@import '_variables';

/* Fonts
-------------------------------------------------- */
@font-face {
    font-family: 'Founders Grotesk';
    src: url('../fonts/FoundersGroteskWeb-Regular.woff2') format('woff2'),
        url('../fonts/FoundersGroteskWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: url('../fonts/FoundersGroteskWeb-Medium.woff2') format('woff2'),
        url('../fonts/FoundersGroteskWeb-Medium.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Founders Grotesk Mono';
    src: url('../fonts/FoundersGroteskMonoWeb-Regular.woff2') format('woff2'),
        url('../fonts/FoundersGroteskMonoWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Founders Grotesk Mono';
    src: url('../fonts/FoundersGroteskMonoWeb-Bold.woff2') format('woff2'),
        url('../fonts/FoundersGroteskMonoWeb-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

/* Base Styles
-------------------------------------------------- */
html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

/* NOTE
html is set to 62.5% so that all the REM measurements throughout
are based on 10px sizing. So basically 1.5rem = 15px :) */
html {
    font-size: 62.5%;
}

body {
    background: $lightgray;
    font-size: 1.8rem;
    line-height: 1.4;
    font-weight: 400;
    font-family: 'Founders Grotesk', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: $darkgray;
}


/* Typography
-------------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    margin: 0;
}

.heading__alpha,
.heading__beta,
.heading__gamma,
.heading__delta,
.heading__epsilon,
.heading__zeta {
    margin: 0;
    font-style: normal;
    font-weight: 300;
}

.heading__alpha {
    font-size: 3rem;
    letter-spacing: -0.1rem;
    line-height: 1.2;
}

.heading__beta {
    font-size: 2.5rem;
    letter-spacing: -0.1rem;
    line-height: 1.25;
}

.heading__gamma {
    font-size: 2.6rem;
    letter-spacing: -0.1rem;
    line-height: 1.3;
}

.heading__delta {
    font-family: 'Founders Grotesk Mono', Consolas, 'Liberation Mono', Courier, monospace;
    font-size: 2rem;
    letter-spacing: -0.08rem;
    line-height: 1.35;
}

.heading__epsilon {
    font-size: 1.8rem;
    letter-spacing: -0.05rem;
    line-height: 1.5;
}

.heading__zeta {
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: 1.6;
}

.page_description {
    margin: 0 auto;
    max-width: 80vw;
}

@media (min-width: 900px) {
    .page_description {
        max-width: 70vw;
    }
}

.text__description {
    margin: 1rem auto 2.5rem;
}

@media (min-width: 900px) {
    .text__description {
        margin-right: 0.5rem;
    }
}

.text__mono {
    font-family: 'Founders Grotesk Mono', Consolas, 'Liberation Mono', Courier, monospace;
}

.text__nowrap {
    white-space: nowrap;
}

.text__highlight {
    color: $red;
}

/* Links
-------------------------------------------------- */
a {
    color: $red;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.link-cover {
    color: $lightgray;
    cursor: pointer;
    display: block;
    text-decoration: none;
    width: 100%;
    height: 100%;
}


/* Buttons
-------------------------------------------------- */
.button {
    display: inline-block;
    margin: 0 auto;
    padding: 1rem 2rem;
    text-align: center;
    font-weight: normal;
    letter-spacing: 0.1rem;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 2px solid $lightgray;
    cursor: pointer;
    font-family: 'Founders Grotesk Mono', Consolas, 'Liberation Mono', Courier, monospace;
    font-size: 1.5rem;
}

.button--primary {
    background-color: $red;
    color: $lightgray;
    text-decoration: underline;
}

.contact__button,
.button--primary a,
.button--primary a:visited {
    color: $lightgray;
    text-decoration: none;
}

.button--disabled {
    background-color: rgba($darkgray, 0.1);
    color: rgba($darkgray, 0.75);
    cursor: default;
}

.button--cta {
    border-color: transparent;
    border-radius: 50%;
    font-family: 'Founders Grotesk', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    font-size: 2.85rem;
    line-height: 1.1;
    text-align: center;
    text-decoration: none;
    white-space: normal;
    margin: 0 auto;
    padding: 2rem 0;
    position: relative;
    width: 12rem;
    height: 12rem;
}

.button--cta:hover {
    text-decoration: none;
}

.button--cta-text {
    border-bottom: 2px solid $lightgray;
}

.button--cta__gift {
    padding: 2.5rem 0;
    width: 14rem;
    height: 14rem;
}

.hero_cta--secondary {
    position: relative;
    top: -6rem;
    left: -50%;
    z-index: 1;
}

.button--size-large {
    border: 2px solid $lightgray;
    font-size: 3.6rem;
    line-height: 1;
    padding: 2rem 1rem;
    width: 14rem;
    height: 14rem;
}

.button--size-large .button--cta-text {
    left: 0;
}

/* Lists
-------------------------------------------------- */
.list {
    list-style-type: none;
    margin: 0 0 1rem;
    padding: 0;
}

.list-term {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.list-definition {
    margin: 0 0 1rem 0;
}

.list-term_faq {
    color: $yellow;
}

.list-item {
    text-align: center;
}

@media (min-width: 900px) {
    .list-item {
        margin-right: 3%;
    }
}

.media {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: center;
}

.media__asset {
    text-align: center;
}

.media__img {
    filter: contrast(0.8) grayscale(1) sepia(0.1);
    width: 224px;

    &:hover {
        filter: none;
    }
}

.media__header {
    text-align: center;
}

@media (min-width: 600px) {
    .media__img {
        width: 150px;
    }
}

@media (min-width: 900px) {
    .media__img {
        width: 230px;
    }

    .media__content {
        max-width: 230px;
    }
}

@media (min-width: 1400px) {
    .media {
        display: flex;
        margin-bottom: 5rem;
    }
}

.list-item_social-media {
    font-size: 4rem;
    display: inline;
    padding: 0 1rem;
}

.list-item_social-media a {
    color: $darkgray;
}

/* Beverages menu overrides */
.page-beverages {
    .content {
        max-width: 90vw;
    }

    .for--tv {
        display: none;
    }

    @media (min-width: 900px) {
        .content {
            max-width: 70vw;
        }
    }

    @media (min-width: 1920px) {
        .content {
            max-width: 100vw;
        }
    }
}

.bevs_list {
    margin-top: 2rem;

    .media {
        align-items: center;
        margin-bottom: 1.5rem;
    }

    .media__asset {
        margin-right: 1rem;
    }

    .media__img {
        width: 80px;
    }

    .media__content {
        max-width: none;
        width: 240px;
    }

    .media__header {
        text-align: left;
    }

    .heading__beta {
        font-size: 2rem;
        line-height: 1.1;
    }

    .heading__zeta {
        font-style: italic;
        letter-spacing: 0.02rem;
    }

    .media__body {
        text-align: left;
    }

    .text__description {
        font-size: 1.6rem;
        line-height: 1.1;
        margin: 0.5rem 0;
    }

    .bev_deets {
        font-family: 'Founders Grotesk Mono', Consolas, 'Liberation Mono', Courier, monospace;
        font-size: 1.25rem;
        line-height: 0.85;
        margin-bottom: 0;

        li {
            display: inline-block;
            margin-right: 1.25rem;
        }
    }
}

@media (min-width: 600px) {
    .bevs_list {
        .media__asset {
            margin-right: 2rem;
        }

        .media__img {
            width: 120px;
        }

        .media__content {
            width: 320px;
        }

        .media__header {
            .heading__beta {
                font-size: 2.5rem;
            }
        }

        .text__description {
            font-size: 1.8rem;
        }
    }
}

@media (min-width: 900px) {
    .bevs_list {
        .media__content {
            width: 400px;
        }
    }
}

@media (min-width: 1200px) {
    .bevs_list {
        .media__content {
            width: 500px;
        }

        .heading__zeta {
            font-size: 1.8rem;
            line-height: 1.1;
            margin-bottom: 1rem;
        }

        .bev_deets {
            font-size: 1.5rem;
            letter-spacing: -1px;
            line-height: 1;
        }

        .text__description {
            font-size: 2rem;
        }
    }
}

/* TV beer menu */
@media (min-width: 1920px) and (max-width: 1920px) {
    .page-beverages {

        .page__header,
        .centered_absolutely,
        .section_hero--secondary,
        .footer {
            display: none;
        }

        .section {
            padding: 0;
        }

        .content h1 {
            background-color: $darkgray;
            color: $lightgray;
            font-size: 6rem;
        }

        .for--tv {
            display: inline;
    
            &::before {
                content: ':'
            }
        }
    
        .not_for--tv {
            display: none;
        }
    }

    .bevs_list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(8, 1fr);
        grid-auto-flow: column;

        .media__img {
            filter: none;
            width: 100px;
        }

        .media__content {
            width: 600px;
        }

        .media__header {
            .heading__beta {
                font-size: 4rem;
            }

            .heading__zeta {
                font-size: 3.25rem;
            }
        }
    }
}

/* Forms
-------------------------------------------------- */
form {
    margin: 0 auto;
}

@media (min-width: 600px) {
    form {
        max-width: 50vw;
    }
}

@media (min-width: 900px) {
    form {
        max-width: 55rem;
    }
}

fieldset {
    border-width: 0;
    margin: 0;
    padding: 0;
}

legend {
    padding: 1rem 0;
    text-align: left;
}

label {
    display: block;
    font-weight: bold;
    text-align: left;
}

.label-descriptor {
    font-style: italic;
    font-weight: normal;
}

input[type="email"],
input[type="text"],
input[type="number"],
input[type="url"],
select,
textarea {
    border-width: 1px;
    box-shadow: inset 0 0 5px rgba($darkgray, 0.5);
    color: $darkgray;
    font-family: 'Founders Grotesk Mono', Consolas, 'Liberation Mono', Courier, monospace;
    font-size: 2.25rem;
    display: block;
    padding: 0.5rem 0.75rem;
    width: 100%;
}

.radio-option {
    text-align: left;
}

.radio-option label {
    font-weight: normal;
    display: inline;
}

.textarea {
    height: 20rem;
}

#celebrations_section {
    #phone_label {
        display: none;
    }
    #phone {
        display: none;
    }
}

.button-submit {
    margin-top: 2rem;
}

.thankyou_message {
    display: none;
    padding: 1rem 2rem;
}

.section__inverse .thankyou_message {
    background-color: $lightgray;
    color: $darkgray;
}

/* Spacing
-------------------------------------------------- */
button,
.button {
    margin-bottom: 1rem;
}

input,
textarea,
select,
fieldset {
    margin-bottom: 1.5rem;
}

pre,
blockquote,
dl,
figure,
table,
p {
    margin-top: 0;
}

p,
ul,
ol,
form {
    margin-bottom: 2.5rem;
}

p {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 900px) {
    .page-comedian p {
        max-width: 55rem;
    }
}


/* Reusable blocks
-------------------------------------------------- */
/* Responsive iFrame */
.iframe-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.img-intro {
    max-width: 100%;
}

/* Notification banner */
.notification {
    padding: 0.5rem 1rem;
    position: fixed;
    width: 100%;
    z-index: 900;
}

.notification--banner {
    background-color: $green;
    color: $lightgray;
}

.notification--banner a {
    color: $lightgray;
    text-decoration: underline;
}

/* Layout
-------------------------------------------------- */
.section {
    padding: 4rem 0;
}

.section__inverse {
    background: $darkgray;
    color: $lightgray;
}

.section-overlay {
    background: rgba($black, 0.65);
    padding: 5rem 0 5rem 1rem;
    text-align: center;
}

.section_hero--secondary {
    padding: 6rem 1rem 3rem;
    position: relative;
    text-align: center;
}

.section__nobottomspacing {
    padding-bottom: 0;
}

.section__nobottomspacing p:last-child {
    margin-bottom: 0;
}

.section__topborder {
    border-top: 1px solid $darkgray;
}

.content {
    margin: 0 auto;
    max-width: 70vw;
    position: relative;
}

@media (min-width: 900px) {
    .content {
        max-width: 50vw;
    }
}

.centered {
    text-align: center;
}

.centered_absolutely {
    position: absolute;
    left: 50%;
}

.full_width {
    display: block;
    width: 100%;
}

.content_flex__narrow,
.content_flex__medium {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-evenly;
}

@media (min-width: 600px) {
    .content_flex__narrow {
        flex-flow: row nowrap;
    }

    .column__narrow {
        margin-left: 7rem;
    }

    .column__narrow:first-child {
        margin-left: 0;
    }
}

@media (min-width: 900px) {
    .column__narrow {
        margin-right: 3rem;
        max-width: 50%;
    }

    .content_flex__medium {
        flex-flow: row nowrap;
    }

    .column__medium {
        margin-left: 5rem;
    }

    .column__medium:first-child {
        margin-left: 0;
    }
}

@media (min-width: 1200px) {
    .content_flex__wide {
        flex-flow: row nowrap;
    }
}

/* Body */
.page__inner {
    position: relative;
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding: 0 20px;
}

@media (min-width: 600px) {
    .page__inner {
        width: 85%;
        padding: 0;
    }
}

/* List used as a menu of link options */
.menu {
    font-family: 'Founders Grotesk Mono', Consolas, 'Liberation Mono', Courier, monospace;
    font-size: 1.8rem;
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.nav {
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.nav__button {
    background-color: rgba($darkgray, 0.3);
    border-radius: 0 0 0 1rem;
    border-width: 0;
    color: $lightgray;
    font-size: 3.5rem;
    padding: 0.5rem 1.5rem;
    position: absolute;
    top: 0;
    right: 0;
}

.nav__button--open {
    font-size: 5rem;
    line-height: 1;
}

/* Accordions
-------------------------------------------------- */
.section__accordion {
    padding: 0;
}

.button__accordion {
    margin: 0 auto;
    width: 100%;
}

.show-less {
    display: none;
}

.content__accordion {
    display: none;
    margin-top: 2rem;
}

.heading__accordion {
    border-bottom: 1px solid white;
    margin: 2rem 0 1rem;
    padding-bottom: 0.5rem;
    width: 15%;
    white-space: nowrap;
}

/* Overlays
-------------------------------------------------- */
.overlay {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    background-color: rgba($darkgray, 0.75);
    color: $lightgray;
}

.overlay--full {
    padding-bottom: 4rem;
    top: -2.5%;
    left: 0;
    width: 100%;
    height: 108%;
}

/* Loading spinner
-------------------------------------------------- */
.loading {
    border-radius: 50%;
    width: $loading-line-height;
    height: $loading-line-height;
    border: .25rem solid rgba($lightgray, 0.2);
    border-top-color: $lightgray;
    animation: spin $spin-duration infinite linear;
}

/* Page layout
-------------------------------------------------- */
.page__header {
    background: url('https://static.cttcomedy.com/other/audience-bw.jpg') center no-repeat;
    background-size: cover;
    color: $lightgray;
}

.header__inner {
    background-color: rgba($black, 0.65);
}

.header__menu {
    background-color: $darkgray;
    display: none;
    padding-top: 5rem;
}

.header__menu-item {
    border-top: 1px solid $lightgray;
    margin: 0;
}

.header__menu-item .link-cover {
    padding: 1rem 0;
}

@media (min-width: 900px) {
    .nav__button {
        display: none;
    }

    .header__menu {
        display: block;
        padding: 0.6rem 0;
    }

    .header__menu-item {
        border-top: 0;
        display: inline;
    }

    .header__menu-item:after {
        content: " -";
    }

    .header__menu-item:last-child:after {
        content: "";
    }

    .header__menu-item .link-cover {
        display: inline;
    }
}

.hero {
    padding: 3rem 0 9rem;
    text-align: center;
}

.page-comedian .hero {
    padding-bottom: 3rem;
}

.hero__heading {
    margin-bottom: 0;
}

.logo {
    max-width: 300px;
}

@media (min-width: 900px) {
    .hero {
        padding: 6rem 0;
    }
}

.body {
    background-color: $lightgray;
    padding-bottom: 1rem;
}

/* Shows */
.show-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.show-list__item {
    border-bottom: 1px solid $darkgray;
    padding: 3rem 0;
}

.show-list__item:first-child {
    padding-top: 0;
}

.show-list__item:last-child {
    border-bottom: 0;
}

.show-list__highlight {
    color: $red;
    font-size: 2.4rem;
    font-weight: bold;
    margin: 0;
}

.show {
    display: -webkit-flex;
    display: flex;
    position: relative;
}

.show-section {
    flex-grow: 1;
    flex-basis: 20%;
}

.show-section--primary {
    line-height: 1.2;
    margin-right: 1rem;
}

.show__when-day {
    display: inline;
    font-weight: bold;
    text-transform: uppercase;
}

.show__when-ampm {
    font-size: 1rem;
}

.show .button {
    margin-top: 0.75rem;
    width: 100%;
}

.show-section--secondary {
    font-size: 1.4rem;
    display: none;
    position: absolute;
    right: 0%;
}

.show__details {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.details__show-time {
    font-weight: bold;
}

.details__policy {
    font-style: italic;
}

.show-section--earlyLate {
    position: absolute;
    right: 0;
    top: 0;
    color: $red;
    font-size: 125%;
    font-weight: bold;
    max-width: 12rem;
}

.show-section--earlyLate:first-letter {
    text-transform: capitalize;
}

.show-section--tickets-left {
    font-size: 70%;
    color: $darkgray;
    line-height: 34%;
}

.show-section--empty-earlylate {
    height: 29px;
}

@media (min-width: 600px) {
    .show .button {
        width: auto;
    }

    .show-section--earlyLate {
        left: 40vw;
        max-width: unset;
    }
}

@media (min-width: 1200px) {
    .show__when-date {
        display: inline;
    }

    .show__when-time {
        display: none;
    }

    .show-section--earlyLate {
        left: 30vw;
    }

    .show-section--secondary {
        display: block;
    }
}

@media (min-width: 1200px) {
    .show-section--earlyLate {
        left: 20vw;
    }

    .show-section--secondary {
        left: 40vw;
    }
}

/* Restaurants */
.restaurants-section {
    padding-top: 0;
}

.restaurants-map_content {
    max-width: 100%;
}

.restaurant-map {
    height: 30rem;
    margin: 0 auto 2rem;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}

@media (min-width: 600px) {
    .restaurant-map {
        height: 40rem;
    }
}

@media (min-width: 900px) {
    .restaurant-map {
        height: 50rem;
    }
}

.list-restaurants {
    display: grid;
    column-gap: 1.5rem;
}

@media (min-width: 600px) {
    .list-restaurants {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 900px) {
    .list-restaurants {
        grid-template-columns: repeat(3, 1fr);
    }
}

.budget__low {
    color: $green;
}

.budget__medium {
    color: $yellow;
}

.budget__high {
    color: $red;
}

.budget__splurge {
    color: $darkgray;
}

/* Comedians */
.video_player {
    margin-top: 3rem;
    width: 100%;
    height: 40vw;
}

@media (min-width: 900px) {
    .video_player {
        width: 560px;
        height: 315px;
    }
}

/* Footer
-------------------------------------------------- */
.footer {
    background-color: $darkgray;
    color: $lightgray;
}

.fluffy-section {
    padding: 5rem 0;
}

.directions {
    text-align: center;
}

.address {
    font-weight: bold;
}

.map {
    background: url('https://static.cttcomedy.com/map/desktop.png') center no-repeat;
    background-size: cover;
    height: 400px;
}

.contact {
    background-color: $red;
}

.contact__inner {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.contact__logo {
    width: 300px;
}

.contact__button {
    border-color: $lightgray;
    font-size: 2rem;
    padding: 2rem 1rem;
    width: 20rem;
}

.contact__link {
    color: $lightgray;
    border-width: 0;
}

@media (min-width: 900px) {
    .contact__inner {
        flex-direction: row;
    }

    .column {
        flex-grow: 1;
        flex-basis: 20%;
    }
}

.copyright {
    display: block;
    margin-top: 3rem;
}

/* CSS breakpoints
   Using mobile first approach
-------------------------------------------------- */
/* Small devices, tablets: @media (min-width: 600px) {} */
/* Medium devices:         @media (min-width: 900px) {} */
/* Large devices:          @media (min-width: 1200px) {} */