// Color palette
// * Primary
$darkgray: rgb(58, 53, 48);
$lightgray: rgb(229, 214, 203);
$black: rgb(28, 22, 17);
// ** Secondary
$red: rgb(192, 54, 44);
$yellow: rgb(229, 176, 26);
$green: rgb(53, 99, 48);

// Loading spinner
$loading-line-height: 24px;
$spin-duration: 1s;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
