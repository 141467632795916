/* Section: Hero
-------------------------------------------------- */
.hero_section {
  background: rgb(28, 22, 17) url('https://static.cttcomedy.com/other/audience-bw.jpg') no-repeat center;
  background-size: cover;
  padding: 0;
}
.hero_content {
  margin-bottom: 1.5rem;
  max-width: 60%;
}
@media (min-width: 900px) {
  .hero_content {
    margin: 8rem auto 6rem;
    padding-top: 5rem;
  }
}
.hero_logo {
  margin: 0 auto;
}
.hero_cta {
  top: -10rem;
}
.hero_header {
  margin: -10rem 0 3rem;
  text-align: center;
}

/* Section: Producers
-------------------------------------------------- */
.producers_content {
  max-width: 70vw;
}
.producers_header {
  margin-bottom: 1.8rem;
  text-align: center;
  text-decoration: underline;
}

/* Section: Map
-------------------------------------------------- */
.map_section {
  background: $darkgray url('https://static.cttcomedy.com/map/mobile.png') no-repeat center;
  background-size: cover;
  padding: 11rem 0;
}
.map_cta {
  top: -17rem;
  z-index: 1;
}
@media (min-width: 600px) {
  .map_section {
    background-image: url('https://static.cttcomedy.com/map/tablet.png');
  }
}
@media (min-width: 900px) {
  .map_section {
    background-image: url('https://static.cttcomedy.com/map/desktop.png');
  }
}

/* Section: Details
-------------------------------------------------- */
.details_section {
  padding-top: 0;
  position: relative;
  top: -4rem;
}
.details_section .heading__beta {
  border-bottom: 1px solid white;
  width: 15%;
  white-space: nowrap;
}
.details_section .details {
  font-family: 'Founders Grotesk Mono', Consolas, 'Liberation Mono', Courier, monospace;
  font-size: 1.6rem;
}
.block {
  margin-bottom: 3rem;
}
.block_where {
  position: relative;
}

/* Footer
-------------------------------------------------- */
.footer_logo {
  min-width: 100%;
}
@media (min-width: 600px) {
  .footer_logo {
    min-width: 25vw;
  }
}
@media (min-width: 900px) {
  .footer_logo {
    margin: 0;
    text-align: right;
  }
  .footer_cta {
    min-width: 25vw;
  }
}
.footer_button {
  display: block;
  margin: 0 auto 3rem;
  text-decoration: none;
}
@media (min-width: 600px) {
  .footer_button {
    max-width: 15rem;
  }
}
.cta__primary_footer {
  border: 2px solid $lightgray;
}
